import { DropdownIndicatorProps, components } from 'react-select'
import { franchisePageTheme } from '../styles/franchisePageTheme'

export function CustomChevronDropdownIndicator(props: DropdownIndicatorProps<any, boolean>) {
  const color =
    // React select props are a nightmare. just overriding anyhing custom until then
    // @ts-ignore
    props.selectProps.iconOverrideColor ||
    (props.selectProps.menuIsOpen ? franchisePageTheme.colors.raichuOrange : franchisePageTheme.colors.yamabukiGold)

  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill={color}
        transform={props.selectProps.menuIsOpen ? 'rotate(180)' : ''}
      >
        <g id="Icon/20px/Actions/Dropdown">
          <path
            id="Vector"
            d="M10.0041 14.6259C10.215 14.6259 10.4259 14.5415 10.5694 14.3812L17.1 7.69028C17.2435 7.54684 17.3278 7.36121 17.3278 7.15028C17.3278 6.71152 16.9987 6.37402 16.56 6.37402C16.3491 6.37402 16.155 6.4584 16.0115 6.5934L9.54844 13.2H10.4513L3.98812 6.5934C3.85312 6.4584 3.65906 6.37402 3.43969 6.37402C3.00094 6.37402 2.67188 6.71152 2.67188 7.15028C2.67188 7.36121 2.75625 7.54684 2.89969 7.69872L9.43031 14.3812C9.59063 14.5415 9.78469 14.6259 10.0041 14.6259Z"
          />
        </g>
      </svg>
    </components.DropdownIndicator>
  )
}
