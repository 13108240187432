import * as React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { franchiseFormStandardDefaultValues } from '../common/franchiseFormStandardDefaultValues'
import { overrideZodEnumErrorMessage } from '../../../../../utilities/form-validation/overrideZodEnumErrorMessage'
import { z } from 'zod'
import { trimmedStringValidator } from '../../../../../utilities/form-validation/trimmedStringValidator'
import { franchiseTabs } from '../constants/franchiseTabs'
import { fields } from '../common/fields'
import { useSearchParams } from 'next/navigation'
import { franchiseServiceQueryParamName, tabQueryParamName } from '../../../../../common/constants/queryParams'

export type UseFranchiseFormReturn = ReturnType<typeof useFranchiseForm>

overrideZodEnumErrorMessage()

const makeBaseSchema = (isIntoTour = false) =>
  z.object({
    [fields.location]: trimmedStringValidator,
    [fields.tab]: z.nativeEnum(franchiseTabs),
    [fields.service]: z
      .array(z.string())
      .min(1, { message: 'Required' })
      .max(isIntoTour ? 1 : 3, {
        message: isIntoTour ? 'The intro tour canot be booked with other upgrades' : 'Select at most 3 options',
      }),
    /* UPGRADE START */
    [fields.date]: trimmedStringValidator,
    [fields.timeslot]: trimmedStringValidator,
    [fields.paymentMethod]: trimmedStringValidator,
    /* UPGRADE END */
    /* PACKAGE START */
    [fields.package]: trimmedStringValidator,
    /* PACKAGE END */
    /* MEMBERSHIP START */
    [fields.membership]: trimmedStringValidator,
    /* MEMBERSHIP END */
  })

export type FranchiseFullSchema = z.infer<ReturnType<typeof makeBaseSchema>>

// type PrePaymentSchemas<T> = Omit<T, 'paymentMethod'>

// type PackagesSchema = Omit<FullSchema, 'date' | 'timeslot' | 'service' | 'membership'>
// type MembershipSchema = Omit<FullSchema, 'date' | 'timeslot' | 'service' | 'package'>
// type UpgradeSchema = Omit<FullSchema, 'membership' | 'package'>

// type PrePaymentPackagesSchema = PrePaymentSchemas<PackagesSchema>
// type PrePaymentMembershipSchema = PrePaymentSchemas<MembershipSchema>
// type PrePaymentUpgradeSchema = PrePaymentSchemas<UpgradeSchema>

type Input = {
  defaultValues?: Partial<FranchiseFullSchema>
  paymentRequired: boolean
  isEdit?: boolean
}

export const useFranchiseForm = (input: Input) => {
  const params = useSearchParams()
  const queryParamTab = params?.get(tabQueryParamName)
  const serviceQueryParam = params?.getAll(franchiseServiceQueryParamName)

  const introTourAdded = serviceQueryParam?.includes('Intro Tour')
  const tab = input.isEdit ? franchiseTabs.upgrades : queryParamTab

  const dynamicSchema = React.useMemo(() => {
    const paymentModifiedSchema = input.paymentRequired
      ? makeBaseSchema(introTourAdded)
      : makeBaseSchema(introTourAdded).omit({ [fields.paymentMethod]: true })

    switch (tab) {
      case franchiseTabs.upgrades: {
        return paymentModifiedSchema.omit({
          [fields.membership]: true,
          [fields.package]: true,
        })
      }
      case franchiseTabs.memberships: {
        return paymentModifiedSchema.omit({
          [fields.date]: true,
          [fields.service]: true,
          [fields.timeslot]: true,
          [fields.package]: true,
        })
      }
      case franchiseTabs.packages: {
        return paymentModifiedSchema.omit({
          [fields.date]: true,
          [fields.service]: true,
          [fields.timeslot]: true,
          [fields.membership]: true,
        })
      }
      default: {
        return paymentModifiedSchema
      }
    }
  }, [tab, input.paymentRequired, introTourAdded])

  const defaultValues = { ...franchiseFormStandardDefaultValues, ...input.defaultValues }
  const formReturn = useForm<FranchiseFullSchema>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(dynamicSchema),
  })

  const { reset } = formReturn

  React.useEffect(() => {
    reset(undefined, { keepValues: true })
  }, [reset, dynamicSchema])
  return { ...formReturn, defaultValues }
}
