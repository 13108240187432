// TODO could obviously make this better and even get the typings from the gtm gtag library but we need to release this
// within the hour, so just leaving this note for when we use it more/need to add complexity

export enum franchiseGtmEvents {
  purchaseMembership = 'purchase_membership',
}
export function fireFranchiseGtmEvent(eventName: franchiseGtmEvents) {
  if (typeof window === 'undefined') return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: eventName,
  })
}
