import * as React from 'react'
import { gql } from '@apollo/client'
import { type UseFranchiseMembershipReturn } from './useFranchiseMembership'
import { FranchiseCountryEnum, useGetFranchisePaymentMethodsQuery } from '../../../../../generated/graphql'
import { useAccount } from '../../../../auth/hooks/useAccount'
import { cacheFirstFetchPolicyObj } from '../constants/cacheFirstFetchPolicyObj'

gql`
  query GetFranchisePaymentMethods($locationId: String!, $country: FranchiseCountryEnum!) {
    franchise(country: $country) {
      saved_payment_methods(centerId: $locationId) {
        is_default
        account_id
        card_logo
        last_four
        expiry_on
        payment_account_id
      }
    }
  }

  mutation AddPaymentMethod($locationId: String!, $redirectUri: String!, $country: FranchiseCountryEnum!) {
    franchise_add_stored_card(centerId: $locationId, redirectUri: $redirectUri, country: $country)
  }

  mutation DeletePaymentMethod($locationId: String!, $country: FranchiseCountryEnum!, $paymentAccountId: String!) {
    franchise_delete_stored_card(
      center_id: $locationId
      country: $country
      # must use payment_account_id instead of account_id from stored payment methods response
      account_id: $paymentAccountId
    ) {
      success
      error {
        Message
        StatusCode
        InternalMessage
      }
    }
  }
`

export type LocationData = UseFranchiseMembershipReturn['locationData']

export function usePaymentMethods(locationData: LocationData) {
  const { isAuthenticated } = useAccount()
  const { data, ...rest } = useGetFranchisePaymentMethodsQuery({
    ...cacheFirstFetchPolicyObj,
    notifyOnNetworkStatusChange: true,
    skip: !isAuthenticated || !locationData,
    variables: {
      locationId: locationData?.locationId ?? '',
      country: locationData?.country ?? FranchiseCountryEnum.US,
    },
  })

  const paymentMethodOptions = React.useMemo(
    () =>
      (data?.franchise.saved_payment_methods ?? [])
        .slice()
        .sort((a, b) => (a.is_default === b.is_default ? 0 : a.is_default ? -1 : 1))
        .map((x) => ({
          cardType: x.card_logo,
          lastFour: x.last_four,
          title: `${x.card_logo} ${x.last_four}`,
          isDefault: x.is_default,
          paymentId: x.account_id,
          paymentAccountId: x.payment_account_id,
        })),
    [data]
  )

  return { paymentMethodOptions, ...rest }
}
