import { z } from 'zod'
// make radio and select errors just say 'Required' insted of the long enum error message listing all allowable values
export function overrideZodEnumErrorMessage(message: string = 'Required') {
  z.setErrorMap((issue, ctx) => {
    return {
      message:
        issue.code === z.ZodIssueCode.invalid_enum_value || issue.code === z.ZodIssueCode.invalid_type
          ? message
          : ctx.defaultError,
    }
  })
}
