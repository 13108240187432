import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { trimmedStringValidator } from './trimmedStringValidator'

export const usPhoneNumberValidator = trimmedStringValidator.refine((val) => {
  try {
    const parsePhoneNumberRes = parsePhoneNumberWithError(val)
    return parsePhoneNumberRes.country === 'US'
  } catch {
    return false
  }
}, 'Please enter a valid US number')
