import { franchiseTabs } from '../constants/franchiseTabs'

export enum fields {
  location = 'location',
  tab = 'tab',
  package = 'package',
  membership = 'membership',
  service = 'service',
  date = 'date',
  timeslot = 'timeslot',
  paymentMethod = 'paymentMethod',
}

export const fieldsByTab = {
  [franchiseTabs.upgrades]: [fields.service, fields.date, fields.timeslot],
  [franchiseTabs.memberships]: [fields.membership],
  [franchiseTabs.packages]: [fields.package],
}
