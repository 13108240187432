import { franchiseTabs } from '../constants/franchiseTabs'
import { type FranchiseFullSchema } from '../hooks/useFranchiseForm'
import { fields } from './fields'

export const franchiseFormStandardDefaultValues: FranchiseFullSchema = {
  [fields.location]: '',
  [fields.package]: '',
  [fields.membership]: '',
  [fields.tab]: franchiseTabs.upgrades,
  [fields.service]: [],
  [fields.date]: '',
  [fields.timeslot]: '',
  [fields.paymentMethod]: '',
}
