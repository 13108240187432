'use client'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import * as React from 'react'
import { Props as SelectProps, StylesConfig } from 'react-select'
import { selectStyles } from '../common/form-components/selectStyles'
import {
  isNullableSelectOption,
  NonNullableSelectOption,
  NullableSelectOption,
} from '../typescript/guards/isSelectOption'
import { useAutoUpdatingRef } from './useAutoUpdatingRef'
import { usePrevious } from './usePrevious'
import { useToast } from '../features/toast/useToast'

type Input<O> = { options?: NullableSelectOption<O>[]; externalValueString?: string | null; isClearable?: boolean }

function getOption<O>({ options, externalValueString, isClearable }: Input<O> = {}) {
  if (!externalValueString && isClearable !== false) return null
  if (!options?.length && !externalValueString) return null
  const result =
    options?.length && externalValueString ? options.find((x) => x?.value === externalValueString) : options?.[0]
  return result || null
}

type UseReactSelectPropsInput<O> = SelectProps<O> &
  Input<O> & {
    onValueChange?: (input: NullableSelectOption<O>) => void
    onValueInitialized?: (input?: NullableSelectOption<O>) => void
    loading?: boolean
    placeholder?: string
  }
/**
 * helper to get the necessary props to set up a react select including the state handlers. works asynchronously.
 * anytime a new options object or a new externalValueString value come in, it essentially reruns setting the select's options and
 * state. if there is no externalValueString value and allowEmpty is false, it defaults to the first option. the value on
 * the select will be left blank, but the rest of the select will be operational. accepts an onValueChange
 * prop to run optional side effects any time the select's value changes
 */

export function useReactSelectProps<SO extends NullableSelectOption | NonNullableSelectOption>({
  options,
  externalValueString,
  onValueChange,
  loading,
  placeholder,
  isClearable,
  onValueInitialized,
  ...rest
}: UseReactSelectPropsInput<SO> = {}) {
  // we want emptt options to be stable within this component's lifecycle so as to not trigger rerender loops in any consuming hooks
  const emptyOptions = React.useRef([]).current
  // avoid server client mismatches by setting an instanceId via react's useId hook which creates an id based on a hooks
  // placement and callsite within the react tree. this should match on client and server and prevent hydration errors
  const reactTreeId = React.useId()
  const instanceId = rest.instanceId ?? reactTreeId

  const [value, setValue] = React.useState(() => {
    const initialValue = loading ? null : getOption({ options, externalValueString, isClearable })
    onValueInitialized?.(initialValue)
    return initialValue
  })
  const optionsRef = useAutoUpdatingRef(options)
  const previousOptions = usePrevious(options)

  const optionsChanged = !isEqual(options, previousOptions)

  React.useEffect(() => {
    if (!loading) {
      const nextOption = getOption({ externalValueString, options: optionsRef.current, isClearable })
      setValue(nextOption)
    }
  }, [externalValueString, optionsRef, optionsChanged, loading, isClearable])

  const { setErrorToast } = useToast()

  const onValueChangeRef = useAutoUpdatingRef(onValueChange)

  const onChange = React.useCallback(
    (next: any) => {
      if (isNullableSelectOption<SO>(next)) {
        setValue(next)
        onValueChangeRef.current?.(next)
      } else setErrorToast('An error occurred')
    },
    [setErrorToast, onValueChangeRef]
  )

  return {
    ...rest,
    placeholder: loading ? 'loading...' : placeholder,
    styles: merge(selectStyles, rest.styles) as StylesConfig<SO>,
    value,
    setValue,
    onChange,
    options: options || emptyOptions,
    isLoading: loading,
    isDisabled: loading,
    instanceId,
  }
}
