'use client'
import { theme } from '../../../../styles/theme'
import styled from '@emotion/styled'
import { StylesConfig } from 'react-select'
import { transientOptions } from '../../../../common/style/transientOptions'
import AlertTriangleRedFillSvg from '../../../../assets/icons/alert-triangle-red-fill.svg'
import { FieldValues, FormState } from 'react-hook-form'
import { machineTextFormatter } from '../../../../utilities/machineTextFormatter'
import { Row } from '../../../../common/components/Row'
import { z } from 'zod'
import { isValidDateString } from '../../../../typescript/guards/isValidDateString'
import { addYears } from 'date-fns'
import { emailValidator } from '../../../../utilities/form-validation/emailValidator'
import { enum_selfdecode_sex_enum } from '../../../../generated/graphql'
import { trimmedStringValidator } from '../../../../utilities/form-validation/trimmedStringValidator'
import { zipCodeValidator } from '../../../../utilities/form-validation/zipCodeValidator'
import { getCssProp, Styles } from '../../../../utilities/getCssProp'
import { usPhoneNumberValidator } from '../../../../utilities/form-validation/usPhoneNumberValidator'

export const labProfileFieldValidators = {
  birth_date: z
    .string()
    .trim()
    .refine((v) => isValidDateString(v), 'Invalid date')
    .refine(
      (v) => addYears(new Date(v), 18).valueOf() < new Date().valueOf(),
      'Must be at least 18 years old to register.'
    ),
  email: emailValidator,
  first_name: trimmedStringValidator,
  last_name: trimmedStringValidator,
  phone_number: usPhoneNumberValidator,
  sex: z.nativeEnum(enum_selfdecode_sex_enum),
  ship_to_address: trimmedStringValidator,
  ship_to_city: trimmedStringValidator,
  ship_to_state: trimmedStringValidator,
  ship_to_postal_code: zipCodeValidator,
  ship_to_country: trimmedStringValidator,
}

export const Header = styled.div`
  color: ${theme.colors.grayscale900};
  font-family: ${theme.fonts.ogg};
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.72px;
  margin-bottom: 8px;
`

export const Subheader = styled.div`
  color: ${theme.colors.grayscale600};
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 32px;
`

export const Label = styled.label`
  color: ${theme.colors.grayscale900};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  display: block;
`

export const newAccountInputStyles = /* css */ `
  all: unset;
  width: 100%;
  padding: 0 8px;
  color: ${theme.colors.grayscale900};
  font-size: 24px;
  font-weight: 500;
  transition: all 0.3s;
  caret-color: ${theme.colors.primary700};
  
  ::placeholder {
    color: ${theme.colors.grayscale500};
  }
  
  :focus {
    ::placeholder {
      color: ${theme.colors.primary450};
    }
  }`

export const Input = styled.input`
  ${newAccountInputStyles}
`

export const FlexStartRow = styled(Row)`
  justify-content: flex-start;

  :not(:first-of-type) {
    margin-top: 5px;
  }
`

export const RadioInput = styled('input', transientOptions)<{ value: string }>`
  appearance: none;
  margin: 0;
  transition: all 0.3s;
  height: 30px;
  cursor: pointer;
  width: fit-content;
  padding: 6px 8px;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.grayscalewhite};
  color: ${theme.colors.grayscale700};
  min-width: 64px;

  ::before {
    content: '${(p) => machineTextFormatter(p.value).titleCase}';
  }

  :hover:not(:checked) {
    background-color: ${theme.colors.primary100};
  }

  color: ${theme.colors.primary700};
  font-size: 14px;
  font-weight: 600;
  background-color: ${theme.colors.grayscalewhite};

  :checked {
    background-color: ${theme.colors.primary700};
    color: ${theme.colors.grayscalewhite};
  }
`

export const RequirementsSelectContainer = styled.div`
  border-radius: 4px;
  padding-top: 4px;
  transition: background-color 0.3s;
  position: relative;

  margin-bottom: 40px;
`

export const TosRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  color: ${theme.colors.grayscale900};
  font-size: 16px;
  font-weight: 400;
  border-top: 1px solid ${theme.colors.grayscale100};
  border-bottom: 1px solid ${theme.colors.grayscale100};

  button {
    margin-left: 4px;
    margin-right: 16px;
  }

  a {
    color: ${theme.colors.primary700};
    font-size: 14px;
    font-weight: 500;
    text-decoration-line: underline;
    font-family: ${theme.fonts.ibmPlexMono};
  }
`

export const makeGetErrorFromFormState = (formState: FormState<FieldValues>) => (field: string) =>
  formState.errors[field]?.message?.toString() || ''

export const newAccountFormSelectStyles: (hasError?: boolean) => StylesConfig<any> = (hasError?: boolean) => ({
  control(_base, props) {
    return {
      border: 'none',
      display: 'flex',
      borderRadius: '4px 4px 2px 2px',
      background: props.isFocused ? theme.colors.primary100 : 'none',
      transition: 'all 0.3s',
      borderBottom: props.isFocused
        ? `1.5px solid ${theme.colors.primary700}`
        : hasError
          ? `1px solid ${theme.colors.dataRange06PoorText};`
          : `1px solid ${theme.colors.grayscale400};`,
      cursor: 'pointer',
    }
  },
  input(base) {
    return {
      ...base,
      color: theme.colors.grayscale700,
      caretColor: theme.colors.primary700,
      fontSize: 24,
      fontWeight: 500,
      transition: 'all 0.3s',
    }
  },
  placeholder(base, props) {
    return {
      ...base,
      color: props.isFocused ? theme.colors.primary450 : theme.colors.grayscale500,
      fontSize: 24,
      fontWeight: 500,
      transition: 'all 0.3s',
    }
  },

  dropdownIndicator: (base, props) => ({
    ...base,
    color: props.isFocused ? theme.colors.primary700 : theme.colors.grayscale500,
  }),
  indicatorSeparator: () => ({
    visibility: 'hidden',
  }),
  valueContainer(base) {
    return {
      ...base,
      color: theme.colors.grayscale700,
      fontSize: 24,
      fontWeight: 500,
    }
  },
  singleValue(base) {
    return {
      ...base,
      color: theme.colors.grayscale900,
      fontSize: 24,
      fontWeight: 500,
    }
  },
  menu: (base) => ({
    ...base,
    marginTop: 0,
    background: theme.colors.grayscalewhite,
    border: `none`,
    boxShadow: '2.81794px 2.81794px 14.0897px rgba(111, 140, 176, 0.41)',
    borderRadius: 8,
    overflow: 'hidden',
    zIndex: 2,
  }),
  menuList: (base) => ({ ...base, padding: 0 }),
  option: (base, state) => ({
    ...base,
    border: 'none',
    padding: 15,
    color: theme.colors.grayscale700,
    background: state.isSelected || state.isFocused ? theme.colors.primary100 : theme.colors.grayscalewhite,
    fontSize: 24,
    fontWeight: 500,
    cursor: 'pointer !important',
  }),
})

const ErrorSection = styled.div`
  margin-top: 6px;
  color: ${theme.colors.dataRange06PoorText};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.07px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`

export const RequirementsErrorMessage = ({
  message,
  styles,
  svgStyles,
}: {
  message?: string
  styles?: Styles
  svgStyles?: Styles
}) => {
  const cssProp = getCssProp(styles)
  const svgCssProp = getCssProp(svgStyles)

  if (!message) return null
  return (
    <ErrorSection css={cssProp}>
      <AlertTriangleRedFillSvg css={svgCssProp} viewBox="0 0 20 20" />
      {message}
    </ErrorSection>
  )
}

export const CtaSection = styled.div`
  margin: 56px 0 89px;
`

export const InputFieldBottomMargin = styled.div`
  margin-bottom: 40px;
`

// when using component selectors + styled options, options must be inlined to not break the babel transform until
// this(https://github.com/vercel/next.js/issues/46973#issuecomment-1879818741) is resolved
export const InputBottomBorder = styled('div', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{ $hasError: boolean }>`
  height: 1px;
  background-color: ${(p) => (p.$hasError ? theme.colors.dataRange06PoorText : theme.colors.grayscale400)};
  width: 100%;
  transition: all 0.3s;
  margin-top: 0.25px;
  margin-bottom: 0.25px;
`

export const InputContainer = styled.div`
  border-radius: 4px;
  overflow: hidden;
  padding-top: 4px;
  transition: background-color 0.3s;
  position: relative;

  :focus-within {
    background-color: ${theme.colors.primary100};

    ${InputBottomBorder} {
      height: 1.5px;
      margin-top: 0;
      margin-bottom: 0;
      background-color: ${theme.colors.primary700};
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    right: 8px;
    background-image: url('/images/svg/calendar-blue.svg');
  }
`
