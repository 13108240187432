import { ApolloError, gql } from '@apollo/client'
import {
  FranchiseCountryEnum,
  useConfirmBookingSlotForBookedDateMutation,
  useCreateBookingDateMutation,
  useCreateMembershipInvoiceMutation,
  useCreatePackageInvoiceMutation,
  useGetBookingSlotsForBookedDateQuery,
  useGetFranchiseMembershipsQuery,
  useGetFranchisePackagesQuery,
  useGetFranchiseServicesQuery,
  useGetUserCreditsQuery,
  usePayInvoiceMutation,
  useRescheduleBookingMutation,
  useReserveBookingSlotForBookedDateMutation,
  useSetFranchiseLocationMutation,
} from '../../../../../generated/graphql'
import React from 'react'
import { type UseFranchiseFormReturn } from './useFranchiseForm'
import { fields } from '../common/fields'
import { toLocalDateString } from '../../../../../common/dates'
import { format } from 'date-fns'
import { useToast } from '../../../../toast/useToast'
import { franchiseFormStandardDefaultValues } from '../common/franchiseFormStandardDefaultValues'
import { NonNullableSelectOption } from '../../../../../typescript/guards/isSelectOption'
import { cacheFirstFetchPolicyObj } from '../constants/cacheFirstFetchPolicyObj'
import { useAccount } from '../../../../auth/hooks/useAccount'
import { ArrayParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params'
import {
  franchiseLocationQueryParamName,
  franchiseServiceQueryParamName,
  tabQueryParamName,
} from '../../../../../common/constants/queryParams'
import { isThisEnum } from '../../../../../typescript/guards/isThisEnum'
import { franchiseTabs } from '../constants/franchiseTabs'
import { pluralize } from '../../../../../utilities/pluralize'
import { isRealNumber } from '../../../../../typescript/guards/isRealNumber'
import useWhyDidEffectRun from '../../../../../hooks/useWhyDidEffectRun'
import { useAutoUpdatingRef } from '../../../../../hooks/useAutoUpdatingRef'
import { useIsMounted } from '../../../../../hooks/useIsMounted'
import { StateSetter } from '../../../../../typescript/utility'
import { useSetModal } from '../../../../modal/modalState'
import { usePaymentMethods } from './usePaymentMethods'
import { useFranchiseLocations } from './useFranchiseLocations'
import { fireFranchiseGtmEvent, franchiseGtmEvents } from '../../../../analytics/fireFranchiseGtmEvent'

// !!This seems like a lot of broken up unnecessary queries. could probably get a lot of this stuff in a single query
// that is based off the selected location
gql`
  mutation SetFranchiseLocation($locationId: String!, $country: FranchiseCountryEnum!) {
    set_franchise_location(locationId: $locationId, country: $country)
  }

  query GetFranchiseServices($location_id: ID!, $country: FranchiseCountryEnum!) {
    franchise(country: $country) {
      services(centerId: $location_id) {
        name
        id
        description
        duration
        price_info {
          sale_price
        }
      }
    }
  }
  mutation CreateBookingDate($date: LocalDate!, $serviceIds: [String!]!, $location_id: ID!) {
    create_franchise_booking(input: { serviceIds: $serviceIds, centerId: $location_id, date: $date }) {
      id
    }
  }

  mutation RescheduleBooking(
    $date: LocalDate!
    $location_id: ID!
    $invoiceId: String!
    $invoiceItemId: String!
    $serviceId: String!
  ) {
    reschedule: reschedule_franchise_booking(
      input: {
        centerId: $location_id
        invoiceId: $invoiceId
        invoiceItemId: $invoiceItemId
        date: $date
        serviceId: $serviceId
      }
    ) {
      id
    }
  }

  query GetBookingSlotsForBookedDate($bookingId: String!, $country: FranchiseCountryEnum!) {
    franchise(country: $country) {
      get_booking_slots(bookingId: $bookingId) {
        slots {
          Available
          Priority
          SalePrice
          Time
        }
      }
    }
  }

  mutation ReserveBookingSlotForBookedDate($bookingId: String!, $time: String!, $country: FranchiseCountryEnum!) {
    reserve_franchise_appointment_slot(bookingId: $bookingId, time: $time, country: $country) {
      reservation_id
      expiry_time
    }
  }

  mutation ConfirmBookingSlotForBookedDate($bookingId: String!, $country: FranchiseCountryEnum!) {
    confirm_franchise_appointment_booking(bookingId: $bookingId, country: $country) {
      is_confirmed
    }
  }

  mutation CancelBooking($invoiceId: String!, $comments: String!, $country: FranchiseCountryEnum!) {
    cancel_franchise_appointment(invoiceId: $invoiceId, comments: $comments, country: $country) {
      success
    }
  }

  ## MEMBERSHIPS

  query GetFranchiseMemberships($location_id: String!, $country: FranchiseCountryEnum!) {
    franchise(country: $country) {
      get_center_memberships(centerId: $location_id) {
        memberships {
          name
          id
          description
          duration_in_months
          price {
            final
            sales
            tax
          }
          payment_details {
            setup_fee {
              price
              tax
            }
          }
        }
      }
    }
  }

  mutation CreateMembershipInvoice($location_id: String!, $membershipIds: [String!]!, $country: FranchiseCountryEnum!) {
    create_membership_invoice(centerId: $location_id, membershipIds: $membershipIds, country: $country)
  }

  ## PACKAGES
  mutation CreatePackageInvoice($location_id: String!, $packageIds: [String!]!, $country: FranchiseCountryEnum!) {
    create_package_invoice(centerId: $location_id, packageIds: $packageIds, country: $country)
  }

  query GetFranchisePackages($location_id: String!, $country: FranchiseCountryEnum!) {
    franchise(country: $country) {
      center_packages(centerId: $location_id) {
        id
        code
        name
        description
        type
        price {
          currency
          tax
          sales
          final
        }
      }
    }
  }

  ## GENERAL
  mutation PayInvoice(
    $location_id: String!
    $invoiceId: String!
    $paymentAccountId: String!
    $country: FranchiseCountryEnum!
  ) {
    pay_franchise_invoice_with_stored_card(
      center_id: $location_id
      invoice_id: $invoiceId
      redirect_uri: ""
      account_id: $paymentAccountId
      country: $country
    )
  }

  query GetUserCredits($location_id: String, $country: FranchiseCountryEnum!) {
    franchise(country: $country) {
      credits_balance(centerId: $location_id) {
        centerId
        balance
      }
    }
  }
`

export type UseFranchiseMembershipReturn = ReturnType<typeof useFranchiseMembership>

type RescheduleInput = {
  invoiceId: string
  invoiceItemId: string
}

type Input = {
  formMethods: UseFranchiseFormReturn
  rescheduleInputRef?: React.MutableRefObject<RescheduleInput>
  setIsPaymentScreen?: StateSetter<boolean>
  setFranchiseAccountModal?: (modalTab?: franchiseTabs) => void
}
export type PricedOption = {
  label: string
  value: string
  bubbleText?: string | null
  description?: string | null
  priceInDollars: number
  taxesInDollars: number
  preTaxPriceInDollars: number
}

// TODO return to this and expand to allow all of our fields to be synced with query params via a func like this
function useSyncFieldValueWithQueryParam({
  formMethods,
  field,
  isModal,
}: {
  formMethods: Input['formMethods']
  field: fields
  isModal: boolean
}) {
  const { watch, setValue } = formMethods
  const [param, setParam] = useQueryParam(field, StringParam)

  const value = watch(field)
  const refs = useAutoUpdatingRef({ isMounted: useIsMounted(), setParam, setValue, param, value, field, isModal })

  useWhyDidEffectRun(
    (valueChanges, paramChanges) => {
      if (!refs.current.isMounted || refs.current.isModal) return
      if (valueChanges && valueChanges.to !== refs.current.param) {
        const valueChangeToParam = typeof valueChanges.to === 'string' ? valueChanges.to : undefined
        refs.current.setParam(valueChangeToParam)
      } else if (paramChanges && paramChanges.to !== refs.current.value) {
        const paramChangeToValue = typeof paramChanges.to === 'string' ? paramChanges.to : ''
        refs.current.setValue(refs.current.field, paramChangeToValue)
      }
    },
    [value, param, refs]
  )
}

export function useFranchiseMembership({
  formMethods,
  rescheduleInputRef,
  setIsPaymentScreen,
  setFranchiseAccountModal,
}: Input) {
  const isModal = !!rescheduleInputRef

  const [params, setParams] = useQueryParams({
    [franchiseLocationQueryParamName]: StringParam,
    [tabQueryParamName]: StringParam,
    [franchiseServiceQueryParamName]: ArrayParam,
  })
  const { isAuthenticated } = useAccount()

  useSyncFieldValueWithQueryParam({ formMethods, field: fields.tab, isModal })

  const franchiseLocationQueryParam = isModal ? null : params[franchiseLocationQueryParamName]
  const franchiseServiceQueryParamArray = isModal ? null : params[franchiseServiceQueryParamName]
  const tabQueryParam = isModal ? null : params[tabQueryParamName]

  const { setSuccessToast, setErrorToast } = useToast()

  const { watch, getValues, reset, setValue } = formMethods
  const values = watch()

  const [setFranchiseLocation, setFranchiseLocationMutation] = useSetFranchiseLocationMutation()

  const formLocationValue = values[fields.location]

  const { franchiseLocationOptions, ...franchiseLocationsQuery } = useFranchiseLocations()

  React.useEffect(() => {
    if (franchiseLocationQueryParam && franchiseLocationOptions.length && !isModal) {
      const match = franchiseLocationOptions.find((x) => x.label === franchiseLocationQueryParam)?.value
      if (match) setValue(fields.location, match)
      else setParams({ [franchiseLocationQueryParamName]: undefined })
    }
  }, [franchiseLocationOptions, setValue, franchiseLocationQueryParam, setParams, isModal])

  const locationData = React.useMemo(() => {
    const country = franchiseLocationOptions.find((x) => x.value === formLocationValue)?.country
    return country && formLocationValue ? { locationId: formLocationValue, country } : null
  }, [franchiseLocationOptions, formLocationValue])

  React.useEffect(() => {
    if (locationData && franchiseLocationOptions.length) {
      const actionTabValue =
        tabQueryParam && isThisEnum(tabQueryParam, franchiseTabs) && !isModal ? tabQueryParam : franchiseTabs.upgrades
      setValue(fields.tab, actionTabValue)
      const locationParam = franchiseLocationOptions.find((x) => x.value === locationData.locationId)?.label
      if (!isModal) {
        setParams({
          [tabQueryParamName]: actionTabValue,
          [franchiseLocationQueryParamName]: locationParam,
        })
      }
    }
  }, [locationData, franchiseLocationOptions, setValue, tabQueryParam, setParams, isModal])

  React.useEffect(() => {
    if (locationData && isAuthenticated) {
      setFranchiseLocation({ variables: { locationId: locationData.locationId, country: locationData.country } })
    }
  }, [locationData, franchiseLocationOptions, setFranchiseLocation, setValue, isAuthenticated])

  const getFranchiseServicesQuery = useGetFranchiseServicesQuery({
    variables: {
      location_id: locationData?.locationId ?? '',
      country: locationData?.country ?? FranchiseCountryEnum.US,
    },
    skip: !locationData,
    ...cacheFirstFetchPolicyObj,
  })

  const getUsercreditsQuery = useGetUserCreditsQuery({
    variables: { country: locationData?.country ?? FranchiseCountryEnum.US },
    ...cacheFirstFetchPolicyObj,
  })

  const refetchUserFranchiseCreditsBalance = getUsercreditsQuery.refetch

  const serviceOptions: PricedOption[] = React.useMemo(
    () =>
      (getFranchiseServicesQuery.data?.franchise.services ?? []).map((x) => {
        const priceInDollars = x.price_info.sale_price * 100

        return {
          label: x.name,
          value: x.id,
          // temporarily disable as it's incorrect
          // bubbleText: x.duration ? `${x.duration} min` : null,
          description: x.description,
          priceInDollars,
          taxesInDollars: 0,
          preTaxPriceInDollars: priceInDollars,
        }
      }),
    [getFranchiseServicesQuery.data]
  )

  const serviceFormValue = values[fields.service]

  React.useEffect(() => {
    if (franchiseServiceQueryParamArray && serviceOptions.length && !isModal) {
      const matches = serviceOptions
        .filter((x) => franchiseServiceQueryParamArray.includes(x.label))
        .map((x) => x.value)
        .slice(0, 3)
      if (matches.length) setValue(fields.service, matches)
      const serviceNames = serviceOptions
        .filter((x) => matches.includes(x.value))
        .map((x) => x.label)
        .slice(0, 3)
      setParams({ [franchiseServiceQueryParamName]: serviceNames.length ? serviceNames : undefined })
    }
  }, [serviceOptions, setValue, franchiseServiceQueryParamArray, setParams, isModal])

  React.useEffect(() => {
    if (serviceOptions.length) {
      if (!isModal) {
        const serviceNames = serviceOptions
          .filter((x) => serviceFormValue.includes(x.value))
          .map((x) => x.label)
          .slice(0, 3)
        setParams({
          [franchiseServiceQueryParamName]: serviceNames,
        })
      }
    }
  }, [serviceFormValue, serviceOptions, setValue, setParams, isModal])

  const getFranchiseLocationMembershipsQuery = useGetFranchiseMembershipsQuery({
    variables: {
      location_id: locationData?.locationId ?? '',
      country: locationData?.country ?? FranchiseCountryEnum.US,
    },
    skip: !locationData,
    ...cacheFirstFetchPolicyObj,
  })

  const { paymentMethodOptions, ...paymentMethodsQuery } = usePaymentMethods(locationData)
  React.useEffect(() => {
    const defaultPaymentMethodId = paymentMethodOptions.find((x) => x.isDefault)?.paymentId
    if (defaultPaymentMethodId) setValue(fields.paymentMethod, defaultPaymentMethodId)
  }, [paymentMethodOptions, setValue])

  const membershipOptions: PricedOption[] = React.useMemo(() => {
    return (getFranchiseLocationMembershipsQuery.data?.franchise.get_center_memberships?.memberships ?? [])
      .map((x) => {
        const finalPrice = x.price.final * 100
        const setupFee = (x.payment_details.setup_fee?.price ?? 0) * 100

        const taxesInDollars = (x.payment_details.setup_fee?.tax ?? 0) * 100 + (x.price.tax ?? 0) * 100
        const priceInDollars = finalPrice + setupFee
        const preTaxPriceInDollars = priceInDollars - taxesInDollars
        return {
          label: x.name,
          value: x.id,

          bubbleText: !isRealNumber(x.duration_in_months)
            ? null
            : x.duration_in_months === 12
              ? 'Yearly'
              : x.duration_in_months === 1
                ? 'Monthly'
                : x.duration_in_months === 4
                  ? 'Quarterly'
                  : `${x.duration_in_months} ${pluralize('Month', x.duration_in_months)}`,
          description: x?.description,
          priceInDollars,
          taxesInDollars,
          preTaxPriceInDollars,
        }
      })
      .sort((a, b) => b.preTaxPriceInDollars - a.preTaxPriceInDollars)
  }, [getFranchiseLocationMembershipsQuery.data])

  const paymentAccountId = fields.paymentMethod in values ? values[fields.paymentMethod] : ''

  const setModal = useSetModal()

  const sharedOnCompleteMainPage = React.useCallback(
    (input: string, tab: franchiseTabs) => {
      reset({ ...franchiseFormStandardDefaultValues, [fields.location]: getValues(fields.location) })
      setIsPaymentScreen?.(false)
      setSuccessToast(input, {
        ...(setFranchiseAccountModal && { cta: { content: 'View', onClick: () => setFranchiseAccountModal(tab) } }),
      })
      refetchUserFranchiseCreditsBalance()
    },
    [
      reset,
      getValues,
      setSuccessToast,
      setIsPaymentScreen,
      refetchUserFranchiseCreditsBalance,
      setFranchiseAccountModal,
    ]
  )

  const onCompleteReschedule = React.useCallback(() => {
    setSuccessToast('Upgrade rescheduled successfully')
    setModal()
  }, [setModal, setSuccessToast])

  const [createMembershipInvoice, createMembershipInvoiceMutation] = useCreateMembershipInvoiceMutation()
  const [createPackageInvoice, createPackageInvoiceMutation] = useCreatePackageInvoiceMutation()

  const [payInvoice, payInvoiceMutation] = usePayInvoiceMutation()

  const buyMembership = React.useMemo(() => {
    if (!paymentAccountId || !locationData) return null
    return (membershipIds: string[]) =>
      createMembershipInvoice({
        variables: { location_id: locationData.locationId, membershipIds, country: locationData.country },
        onCompleted: (data) =>
          payInvoice({
            variables: {
              location_id: locationData.locationId,
              paymentAccountId,
              invoiceId: data.create_membership_invoice.invoice_id,
              country: locationData.country,
            },
            onCompleted: () => {
              fireFranchiseGtmEvent(franchiseGtmEvents.purchaseMembership)
              sharedOnCompleteMainPage('Membership Purchased', franchiseTabs.memberships)
            },
          }),
      })
  }, [paymentAccountId, createMembershipInvoice, payInvoice, locationData, sharedOnCompleteMainPage])

  const buyPackage = React.useMemo(() => {
    if (!paymentAccountId || !locationData) return null
    return (packageIds: string[]) =>
      createPackageInvoice({
        variables: { location_id: locationData.locationId, packageIds, country: locationData.country },
        onCompleted: (data) =>
          payInvoice({
            variables: {
              location_id: locationData.locationId,
              paymentAccountId,
              invoiceId: data.create_package_invoice.invoice_id,
              country: locationData.country,
            },
            onCompleted: () => sharedOnCompleteMainPage('Package Purchased', franchiseTabs.packages),
          }),
      })
  }, [paymentAccountId, locationData, createPackageInvoice, payInvoice, sharedOnCompleteMainPage])

  const formServiceValue = values[fields.service]

  const [createBookingDate, createBookingDateResults] = useCreateBookingDateMutation()

  const formDateValue = values[fields.date]
  const [rescheduleBooking, rescheduleBookingMutationResult] = useRescheduleBookingMutation()

  React.useEffect(() => {
    if (formDateValue && locationData && formServiceValue[0]) {
      const shared = { location_id: locationData.locationId, date: toLocalDateString(new Date(+formDateValue)) }

      const onError = (e: ApolloError) => {
        setErrorToast(e.message)
      }
      if (rescheduleInputRef) {
        rescheduleBooking({
          variables: { ...rescheduleInputRef.current, serviceId: formServiceValue[0], ...shared },
          onError,
        })
      } else {
        createBookingDate({
          variables: { ...shared, serviceIds: formServiceValue },
          onError,
        })
      }
    }
  }, [
    formServiceValue,
    formDateValue,
    setErrorToast,
    locationData,
    createBookingDate,
    rescheduleInputRef,
    rescheduleBooking,
  ])

  const bookingDateId = rescheduleInputRef
    ? rescheduleBookingMutationResult.data?.reschedule.id
    : createBookingDateResults.data?.create_franchise_booking.id

  const getBookingSlotsForBookedDateQuery = useGetBookingSlotsForBookedDateQuery({
    variables: { bookingId: bookingDateId!, country: locationData?.country ?? FranchiseCountryEnum.US },
    skip: !bookingDateId || !locationData,
    ...cacheFirstFetchPolicyObj,
  })
  type TimeSlot = { Available: boolean; Time: string }

  const timeslotOptions = React.useMemo<NonNullableSelectOption[]>(() => {
    return (getBookingSlotsForBookedDateQuery.data?.franchise.get_booking_slots.slots ?? [])
      .filter((x: TimeSlot) => x.Available)
      .map((x: TimeSlot) => {
        const formattedTime = format(new Date(x.Time), 'h:mm a')
        return {
          label: formattedTime,
          value: x.Time,
        }
      })
  }, [getBookingSlotsForBookedDateQuery.data])

  const formTimeslotValue = values[fields.timeslot]

  const [reserveBookingSlotForBookedDate, reserveBookingSlotForBookedDateMutation] =
    useReserveBookingSlotForBookedDateMutation()

  const [confirmBookingSlotForBookedDate, confirmBookingSlotForBookedDateMutation] =
    useConfirmBookingSlotForBookedDateMutation()

  const confirmBooking = React.useMemo(() => {
    if (formTimeslotValue && bookingDateId && locationData) {
      return () =>
        reserveBookingSlotForBookedDate({
          variables: { bookingId: bookingDateId, time: formTimeslotValue, country: locationData.country },
          onCompleted: () => {
            confirmBookingSlotForBookedDate({
              variables: { bookingId: bookingDateId, country: locationData.country },
              onCompleted: () => {
                if (isModal) onCompleteReschedule()
                else sharedOnCompleteMainPage('Appointment Confirmed', franchiseTabs.upgrades)
              },
            })
          },
        })
    } else {
      return null
    }
  }, [
    bookingDateId,
    reserveBookingSlotForBookedDate,
    formTimeslotValue,
    confirmBookingSlotForBookedDate,
    locationData,
    sharedOnCompleteMainPage,
    isModal,
    onCompleteReschedule,
  ])

  const getFranchisePackagesQuery = useGetFranchisePackagesQuery({
    variables: {
      location_id: locationData?.locationId ?? '',
      country: locationData?.country ?? FranchiseCountryEnum.US,
    },
    skip: !locationData,
    ...cacheFirstFetchPolicyObj,
  })

  const packageOptions: PricedOption[] = React.useMemo(
    () =>
      (getFranchisePackagesQuery.data?.franchise.center_packages ?? [])
        .map((x) => {
          const taxesInDollars = x.price.tax * 100
          const priceInDollars = x.price.final * 100
          const preTaxPriceInDollars = priceInDollars - taxesInDollars
          return {
            label: x.name,
            value: x.id,
            description: x.description,
            priceInDollars: x.price.final * 100,
            taxesInDollars,
            preTaxPriceInDollars,
          }
        })
        .sort((a, b) => b.preTaxPriceInDollars - a.preTaxPriceInDollars),
    [getFranchisePackagesQuery.data]
  )

  const rescheduleLoading = reserveBookingSlotForBookedDateMutation.loading || rescheduleBookingMutationResult.loading

  const credits = React.useMemo(
    () =>
      getUsercreditsQuery?.data?.franchise?.credits_balance?.find((x) => x.centerId === locationData?.locationId)
        ?.balance,
    [getUsercreditsQuery, locationData]
  )

  const isLoading =
    franchiseLocationsQuery.loading ||
    setFranchiseLocationMutation.loading ||
    getFranchiseServicesQuery.loading ||
    createBookingDateResults.loading ||
    getBookingSlotsForBookedDateQuery.loading ||
    reserveBookingSlotForBookedDateMutation.loading ||
    confirmBookingSlotForBookedDateMutation.loading ||
    getFranchiseLocationMembershipsQuery.loading ||
    getFranchisePackagesQuery.loading ||
    createMembershipInvoiceMutation.loading ||
    createPackageInvoiceMutation.loading ||
    payInvoiceMutation.loading ||
    paymentMethodsQuery.loading ||
    rescheduleLoading
      ? {
          franchiseLocationsQuery: !!franchiseLocationsQuery.loading,
          setFranchiseLocationMutation: !!setFranchiseLocationMutation.loading,
          getFranchiseLocationServicesQuery: !!getFranchiseServicesQuery.loading,
          createBookingDateMutation: !!createBookingDateResults.loading,
          getBookingSlotsForBookedDateQuery: !!getBookingSlotsForBookedDateQuery.loading,
          reserveBookingSlotForBookedDateMutation: !!reserveBookingSlotForBookedDateMutation.loading,
          confirmBookingSlotForBookedDateMutation: !!confirmBookingSlotForBookedDateMutation.loading,
          getFranchiseLocationMembershipsQuery: !!getFranchiseLocationMembershipsQuery.loading,
          getFranchisePackagesQuery: !!getFranchisePackagesQuery.loading,
          createMembershipInvoiceMutation: !!createMembershipInvoiceMutation.loading,
          createPackageInvoiceMutation: !!createPackageInvoiceMutation.loading,
          payInvoiceMutation: !!payInvoiceMutation.loading,
          paymentMethodsQuery: !!paymentMethodsQuery.loading,
          rescheduleBookingMutation: rescheduleLoading,
        }
      : null

  return {
    isLoading,
    setFranchiseLocation,
    franchiseLocationOptions,
    serviceOptions,
    timeslotOptions,
    confirmBooking,
    credits,
    membershipOptions,
    packageOptions,
    buyMembership,
    buyPackage,
    paymentMethodOptions,
    locationData,
  }
}
