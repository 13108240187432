import { RequirementsErrorMessage } from '../../../../shop/features/requirements/common'

export function FranchiseErrorMessage(props: Parameters<typeof RequirementsErrorMessage>[0]) {
  return (
    <RequirementsErrorMessage
      {...props}
      styles={{ fontSize: 12, fontWeight: 500 }}
      svgStyles={{ width: 14, height: 14 }}
    />
  )
}
