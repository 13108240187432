'use client'
import React, { DependencyList } from 'react'
import { ArrayElement } from '../typescript/utility'

type Dep = ArrayElement<DependencyList> | NoDepsMessage
type Changes = ({ from: Dep; to: Dep } | null)[]

const noDepsMessage = 'NO DEPENDENCY ARRAY PROVIDED. EFFECT WILL ALWAYS RUN'
type NoDepsMessage = typeof noDepsMessage

// TODO make these types better so the effect get sthe dep types when you actually use this hook
export default function useWhyDidEffectRun(effect: (...changes: Changes) => void, deps: DependencyList) {
  const previousDepsRef = React.useRef<[] | DependencyList>([])
  const effectRef = React.useRef(effect)

  effectRef.current = effect

  React.useEffect(() => {
    const previousDeps = previousDepsRef.current
    let changes: Changes = []

    // case were no deps array is provided
    if (!deps || !Array.isArray(deps)) {
      changes = [{ from: noDepsMessage, to: noDepsMessage }]
    }

    if (Array.isArray(deps)) {
      changes = deps.map((to, i) => {
        const from = previousDeps[i]
        return Object.is(from, to) ? null : { from, to }
      })
    }
    previousDepsRef.current = deps
    effectRef.current(...changes)
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}
