import { StylesConfig } from 'react-select'
import { theme } from '../../styles/theme'

export const selectStyles: StylesConfig = {
  // clearIndicator: (base) => ({ ...base }),
  container: (base) => ({ ...base, width: '100%' }),
  control: (base, provided) => ({
    ...base,
    boxShadow: 'inset -2.5px -2.5px 5px rgba(27, 36, 74, 0.05), inset 2.5px 2.5px 5px rgba(27, 36, 74, 0.25)',
    borderRadius: '18px',
    border: 'none',
    background: theme.colors.grayscale000,
    minHeight: 34,
    height: 34,
    // @ts-ignore
    ...((provided.selectProps.hasError || provided.isFocused) && {
      outline: '0 solid rgba(255,255,255, 0)',
      bordercolor: 'rgba(255,255,255, 0)',
      boxShadow: `0 0 5px 1px ${provided.isFocused ? theme.colors.primary700 : theme.colors.outOfRange}`,
    }),
  }),
  dropdownIndicator: (base) => ({ ...base, color: theme.colors.grayscale900 }),
  // group: (base) => ({ ...base }),
  // groupHeading: (base) => ({ ...base }),
  // indicatorsContainer: (base) => ({ ...base, background: bg }),
  indicatorSeparator: () => ({
    visibility: 'hidden',
  }),
  // input: (base) => ({ ...base }),
  // loadingIndicator: (base) => ({ ...base }),
  // loadingMessage: (base) => ({ ...base }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    background: theme.colors.grayscalewhite,
    border: `1px solid ${theme.colors.grayscale200}`,
    boxShadow: '2.81794px 2.81794px 14.0897px rgba(111, 140, 176, 0.41)',
    borderRadius: 8,
    overflow: 'hidden',
    zIndex: 2,
  }),
  menuList: (base) => ({ ...base, padding: 0 }),
  // menuPortal: (base) => ({ ...base }),
  // multiValue: (base) => ({ ...base }),
  // multiValueLabel: (base) => ({ ...base }),
  // multiValueRemove: (base) => ({ ...base }),
  // noOptionsMessage: (base) => ({ ...base }),
  option: (base, state) => ({
    ...base,
    border: 'none',
    padding: 15,
    color: theme.colors.grayscale900,
    background: state.isSelected
      ? theme.colors.primary200
      : state.isFocused
        ? theme.colors.grayscale000
        : theme.colors.grayscalewhite,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.colors.grayscale300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
  }),

  singleValue: (base, state) => ({
    ...base,
    color: theme.colors.grayscale900,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    opacity: state.selectProps.isLoading ? 0.5 : 1,
  }),
  valueContainer: (base) => ({ ...base }),
}

export const angularSelectStyles: StylesConfig = {
  // clearIndicator: (base) => ({ ...base }),
  container: (base) => ({ ...base, width: '100%' }),
  control: (base, provided) => ({
    ...base,
    border: `1px solid ${theme.colors.grayscale200}`,
    borderRadius: 0,
    background: theme.colors.grayscale000,
    color: 'red',
    minHeight: 30,
    height: 30,
    // @ts-ignore
    ...((provided.selectProps.hasError || provided.isFocused) && {
      outline: '0 solid rgba(255,255,255, 0)',
      bordercolor: 'rgba(255,255,255, 0)',
      boxShadow: `0 0 5px 1px ${provided.isFocused ? theme.colors.primary700 : theme.colors.outOfRange}`,
    }),
  }),
  indicatorsContainer: (base) => ({
    ...base,
    background: theme.colors.grayscale200,
    height: 28,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  }),
  dropdownIndicator: (base) => ({ ...base, color: theme.colors.grayscale900 }),
  // group: (base) => ({ ...base }),
  // groupHeading: (base) => ({ ...base }),
  // indicatorsContainer: (base) => ({ ...base, background: bg }),
  indicatorSeparator: () => ({
    visibility: 'hidden',
  }),
  // input: (base) => ({ ...base }),
  // loadingIndicator: (base) => ({ ...base }),
  // loadingMessage: (base) => ({ ...base }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    background: theme.colors.grayscalewhite,
    border: `1px solid ${theme.colors.grayscale200}`,
    boxShadow: '2.81794px 2.81794px 14.0897px rgba(111, 140, 176, 0.41)',
    borderRadius: '0 0 8px 8px',
    overflow: 'hidden',
    zIndex: 2,
  }),
  menuList: (base) => ({ ...base, padding: 0 }),
  // menuPortal: (base) => ({ ...base }),
  // multiValue: (base) => ({ ...base }),
  // multiValueLabel: (base) => ({ ...base }),
  // multiValueRemove: (base) => ({ ...base }),
  // noOptionsMessage: (base) => ({ ...base }),
  option: (base, state) => ({
    ...base,
    border: 'none',
    color: theme.colors.grayscale900,
    fontSize: 12,
    fontFamily: theme.fonts.realizeMe,
    fontWeight: 500,
    background: state.isSelected
      ? theme.colors.primary200
      : state.isFocused
        ? theme.colors.grayscale000
        : theme.colors.grayscalewhite,
  }),
  input: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
    color: theme.colors.grayscale900,
    fontSize: 12,
    fontFamily: theme.fonts.realizeMe,
    fontWeight: 500,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 12,
    fontFamily: theme.fonts.realizeMe,
    fontWeight: 500,
    color: theme.colors.grayscale300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
  }),

  singleValue: (base, state) => ({
    ...base,
    color: theme.colors.grayscale900,
    fontSize: 12,
    fontFamily: theme.fonts.realizeMe,
    fontWeight: 500,
    opacity: state.selectProps.isLoading ? 0.5 : 1,
  }),
  valueContainer: (base) => ({
    ...base,
    display: 'flex',
    color: theme.colors.grayscale900,
    fontSize: 12,
    fontFamily: theme.fonts.realizeMe,
    fontWeight: 500,
    alignItems: 'center',
    padding: '0 12px',
  }),
}
